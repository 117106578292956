.sat-modal .modal-dialog {
    max-width: 80%;
    margin: 1.75rem auto;
}

.iframe-modal .modal-dialog {
    max-width: 95%;
    max-height: 97%;
    margin: 1.75rem auto;
}

.gray-bg {
    background-color: #181b1f;
    padding: "10px";
}

.carousel .slide { display: flex; justify-content: center; }

.carousel.carousel-slider .control-arrow {
    background: rgba(0,0,0,0.2) !important;
    opacity: 0.4 !important;
    padding-inline: 2.5% !important;
}

.carousel.carousel-slider .control-arrow:focus {
    background: rgba(0,0,0,0.2) !important;
    opacity: 0.4 !important;
}

.carousel.carousel-slider .control-arrow:hover {
    background: rgba(0,0,0,0.4) !important;
    opacity: 1 !important;
}

.dropdown-item-toggle{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0);
    border: 0;
}

.dropdown-item-toggle:hover{
    background-color: var(--bs-dropdown-link-hover-bg);
}

.acc-item-toggle{
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.03);
    border: 0;
}

.acc-item-toggle:hover{
    background-color: rgba(255, 255, 255, 0.075);
}

.dropdown-delete{
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0);
    border: 0;
    color: red;
}

.dropdown-delete:hover{
    background-color: darkred;
    color: white;
}

.dropdown-delete:disabled{
    background-color: rgb(65, 65, 65);
    color: rgb(180, 103, 103);
}
