.dark-overlay .tooltip-inner{
    background-color: #252d33; /* Dark background */
    color: #fff;
    max-width:fit-content;
}

.style-source{
    color: #2d99d8;
    cursor: pointer;
}

.style-source:hover,
.style-source:focus {
    color: #38d1ec;
    outline: none;
}

.style-link{
    color: #30a3e6;
}

.style-link:hover{
    color: #7ed4e4; 
}

.spacefana-modal-dialog {
    width: 90% !important;
    max-width: 90% !important;
}

.orbital-modal-dialog {
    width: 95% !important;
    max-width: 95% !important;
}

.spacefana-modal-dialog .modal-content {
    height: 100vh;
}

.spacefana-modal-dialog .modal-body {
    height: calc(90vh - 120px); /* Adjust based on your header and footer heights */
    overflow-y: auto;
} 

.spacefana-modal-header {
    padding: 0.5rem 1rem; /* Reduce padding */
}

.spacefana-modal-header .btn-close {
    padding: 0.5rem; /* Reduce close button size */
    font-size: 0.8rem; /* Make close button icon smaller */
}

.spacefana-modal-header p {
    font-size: 1rem; /* Adjust title font size */
    margin-right: 2rem; /* Give space for close button */
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .order-1 {
        order: 1;
    }
    .order-2 {
        order: 2;
    }
}

@media (min-width: 769px) {
    .order-md-1 {
        order: 1;
    }
    .order-md-2 {
        order: 2;
    }
}

.button-container {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #000; /* Adjust the background color as needed */
    z-index: 1000; /* Ensure it stays on top of other elements */
    padding: 10px; /* Add some padding for aesthetics */
}

.left-align {
    text-align: left !important;
}