.poi-modal .modal-dialog {
    width: 25%;
    height: auto;
    margin: 1.75rem auto;
}

.poi-edit {
    background-color: #181b1f;
    padding-top: 2%;
    padding-bottom: 2%;
}

.MuiTimeline-root {
    padding: 0px 16px !important;
}

.edit-box{
    color:#ffc107;
    height: 16px;
    vertical-align: text-top;
}

.edit-box :hover{
    color:#faebbf;
}

.newSchemaModal{
    width: 100% !important;
}