.card{
    background-color: #181b1f !important;
}

.card-text{
    font-size: 16px;
}

.bold{
    font-weight:700;
}

.btn-copy{
    background-color:#181b1f !important;
    border-color:#181b1f !important;
}

.btn-copy:hover{
    background-color:#2f343b !important;
    border-color:#2f343b !important;
}

.btn-copy:active, .btn-copy:focus{
    background-color:#00832c !important;
    border-color:#00832c !important;
}

.carousel .slide { display: flex; justify-content: center; }

.dark-tooltip .tooltip-inner {
    background-color: #343a40; /* Dark background */
    color: #fff; /* Light text */
    max-width: 24rem;
}
.dark-tooltip .arrow::before {
    border-top-color: #343a40; /* Arrow color for 'top' placement */
}

.loading-placeholder {
    width: 100%;
    height: 200px;
    background-color: #2a2e35;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #666;
}

.spinner-border {
    width: 2rem;
    height: 2rem;
}

.my-4 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}