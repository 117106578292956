@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/bootstrap'; 

* {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

@keyframes rotate-animation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.rotating-image {
    animation: rotate-animation 2s linear infinite;
}

body
{
    background:#111217;
}

.yellow{
    color: #ffc708
}

.logo-text{
    font-size: 30px;
    font-weight: 700;
}

/* About Page */
.avatars{
    max-width: "100%";
    display: "grid";
    grid-template-columns: "repeat(auto-fill, 250px)";
    justify-content: "space-around";
    grid-gap: 40
}

p{
    font-size: 1.5rem;
}

h1{
    font-size: 3rem;

}
h4{
    font-size: 2.125rem;
}


/* Main Page */

.card-text{
    font-size: "16px";
}
